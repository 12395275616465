import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './style.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "./views/Common/scrollToTop";
import Home from './views/Home';
import ContactUs from './views/ContactUs';
import CreditUnion from './views/CreditUnion';
AOS.init();
const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)
function App() {
  return (
    <BrowserRouter>
		<ScrollToTop />
		<Suspense fallback={loading}>
			<Routes>
				<Route exact path="/" name="Home" element={<Home />} />
				<Route exact path="/contact-us" name="Home" element={<ContactUs />} />
				<Route exact path="/credit-union" name="Home" element={<CreditUnion />} />
			</Routes>
		</Suspense>
	</BrowserRouter>
  );
}

export default App;
