import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
	
	return (
		<footer>
      <img className="footer_bg_image" src="images/footer_bg.svg" alt="" />
      <div className="container position_relative">
        <div className="row">
          <div className="logo_footer text-center pb-5" data-aos="fade-down" data-aos-duration="1000">
            <img src="images/logo_light.svg" alt="" />
          </div>
          <div className="col-md-6 col-sm-12" data-aos="fade-down" data-aos-duration="1000">
            <div className="footer_image_box text-center">
              <div className="footer_img">
                <img src="images/map_ic.svg" alt="" />
              </div>
              <h4 className="footer_box_title py-4">Carrollton, Texas</h4>
            </div>
          </div>
          <div className="col-md-6 col-sm-12" data-aos="fade-down" data-aos-duration="1000">
            <div className="footer_image_box text-center">
              <div className="footer_img">
                <img src="images/mail_ic.svg" alt="" />
              </div>
              <h4 className="footer_box_title py-4"><Link className="text-white" to="mailto:contact@credics.com">contact@credics.com</Link></h4>
            </div>
          </div>
        </div>
        <ul className="footer_navbar d-flex justify-content-between align-items-center" data-aos="fade-down" data-aos-duration="1000">
          <li className="footer_nav_items">
			<Link className="footer_links" to="/#our_product">Products</Link>
		  </li>
          <li className="footer_nav_items">
			<Link className="footer_links" to="/credit-union">Credit union</Link>
		  </li>
          <li className="footer_nav_items">
			<Link className="footer_links" to="/#business_section">Business Types</Link>
		  </li>
          <li className="footer_nav_items">
			<Link className="footer_links" to="/#about_section">About</Link>
		  </li>
        </ul>
        <div className="Copyright_area text-center" data-aos="fade-down" data-aos-duration="1000">
          <span className="Copyright_text">Copyright © 2024 Credics. All Rights Reserved.</span>
        </div>
        <ul className="social_media_area d-flex align-items-center gap-3 justify-content-center pt-5" data-aos="fade-down" data-aos-duration="1000">
          <li className="socila_media_items">
            <Link className="social_media_links" to="https://www.linkedin.com/company/credics-technologies-ltd./about/?viewAsMember=true">
				<img src="images/linkedin_ic.svg" alt="" />
			</Link>
          </li>
        </ul>
      </div>
    </footer>
   
	)
}

export default Footer
