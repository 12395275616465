import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from './Common/Header';
import Footer from './Common/Footer';

const ContactUs = () => {
	const [form, setForm] = useState({ first_name: '', first_name_error: '', last_name: '', last_name_error: '', phone_no: '', phone_no_error: '', email: '', email_error: '', message: '', message_error: '' });
	const [formsuccess, setFormSuccess] = useState('');
	const [formError, setFormError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	
	useEffect(() => {
	  if (formsuccess) {
		const timeout = setTimeout(() => setFormSuccess(''), 4000);
		return () => {
		  clearTimeout(timeout);
		};
	  }
	}, [formsuccess]);

	useEffect(() => {
		if (formError) {
		  const timeout = setTimeout(() => setFormError(''), 4000);
		  return () => {
			clearTimeout(timeout);
		  };
		}
	  }, [formError]);
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}
	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		if (form.first_name === '') {
			newEdit.first_name_error = 'The first name is required.';
			validateData = false;
		}
		if (form.last_name === '') {
			newEdit.last_name_error = 'The last name is required.';
			validateData = false;
		}
		if (form.phone_no === '') {
			newEdit.phone_no_error = 'The phone number is required.';
			validateData = false;
		}
		if (form.phone_no === '') {
			newEdit.phone_no_error = 'The phone number is required.';
			validateData = false;
		}
		if (form.message === '') {
			newEdit.message_error = 'The message is required.';
			validateData = false;
		}
		if (form.email === '') {
			newEdit.email_error = 'The email is required.';
			validateData = false;
		} else {
			var check_email = /\S+@\S+\.\S+/.test(form.email);
			if (!check_email) {
				newEdit.email_error = 'Email is invalid';
				validateData = false;
			}
		}
		setForm(newEdit);
		return validateData;
	}

	const handleSubmit = async (e) => {
		setFormSuccess('');

		// Set loading state to true
		setIsLoading(true);

		//Before submit validate form
		if (validateForm()) {
			//submitForm({ email: form.email, password: form.password })
			const response = await axios.post('https://credicsbackend.obsidiantechno.com/api/send-mail',form);
			console.log('responseresponseresponse',response.status);
			if(response.status == 200) {
				//setFormSuccess('Form is submited successfully someone from team will contact you soon');
				toast.success('Form is submited successfully someone from team will contact you soon!');
				setForm({ first_name: '', first_name_error: '', last_name: '', last_name_error: '', phone_no: '', phone_no_error: '', email: '', email_error: '', message: '', message_error: '' });
			} else {
				toast.error('Form is not submited successfully please try again!');
				//setFormError('Form is not submited successfully please try again');
			}
			// Set loading state to false
			setIsLoading(false);
		}
	}
	
	return (
	<>
		<Header />
			<main>

      <section className="form_section">
        <div className="container position_relative">
          <div className="innerpage_banner text-center">
            <h1 className="banner_heading">Contact <span className="color_text">Us</span></h1>
        </div>
		<ToastContainer />
          <div className="row">
            <div className="col-lg-12">
              <div className="contact_form_area">
                <form className="form_contact">
                  <div className="form_inner">
                    <div className="form_inner_area">
                      <input type="text" name="first_name" id="first_name" value={form.first_name} onChange={valueChanged} className="form-control" placeholder="First Name" />
					  <span className="small text-danger">{form.first_name_error}</span>
                    </div> 
                    <div className="form_inner_area">
                      <input type="text" name="last_name" id="last_name" value={form.last_name} onChange={valueChanged} className="form-control" placeholder="Last Name" />
                      <span className="small text-danger">{form.last_name_error}</span>
                    </div> 
                  </div>
                  <div className="form_inner">
                    <div className="form_inner_area">
                      <input type="email" name="email" id="email" value={form.email} onChange={valueChanged} className="form-control" placeholder="Email address*" />
					  <span className="small text-danger">{form.email_error}</span>
                    </div> 
                    <div className="form_inner_area">
                      <input type="tel" name="phone_no" id="phone_no" value={form.phone_no} onChange={valueChanged} className="form-control" placeholder="Phone Number*" />
					  <span className="small text-danger">{form.phone_no_error}</span>
                    </div> 
                  </div>
                  <div className="form_inner">
                    <div className="form_inner_textarea">
                      <textarea name="message" className="form-control" id="message" value={form.message} onChange={valueChanged} placeholder="Message" />
					  <span className="small text-danger">{form.message_error}</span>
                    </div>
                  </div>
				  {/* <span className="small text-success">{formsuccess}</span>
				  <span className="small text-error">{formError}</span> */}
                  <div className="form_inner">
                    <div className="form_inner_btn">
                      <button type="button" id="submit_btn" className="btn submit_btn" onClick={handleSubmit}>Submit</button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map_section">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.932648377382!2d-122.33638232318147!3d47.607999287880304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906ab3f905c4b1%3A0x96bf575ff75ab1aa!2s411%20University%20St%2C%20Seattle%2C%20WA%2098101%2C%20USA!5e0!3m2!1sen!2sin!4v1704802678387!5m2!1sen!2sin" style={{ width: "100%", height: "450px", border: '0px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </section>
    </main>
    
		<Footer />
	</>
	)
}

export default ContactUs
