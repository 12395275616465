import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from './Common/Header';
import Footer from './Common/Footer';

const Home = () => {
	const [form, setForm] = useState({ email: '', email_error: '' });
	const [formsuccess, setFormSuccess] = useState('');
	
	useEffect(() => {
	  if (formsuccess) {
		const timeout = setTimeout(() => setFormSuccess(''), 4000);
		return () => {
		  clearTimeout(timeout);
		};
	  }
	}, [formsuccess]);
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}
	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		if (form.email === '') {
			newEdit.email_error = 'The email field is required.';
			validateData = false;
		} else {
			var check_email = /\S+@\S+\.\S+/.test(form.email);
			if (!check_email) {
				newEdit.email_error = 'Email is invalid';
				validateData = false;
			}
		}
		setForm(newEdit);
		return validateData;
	}

	const handleSubmit = (e) => {
		setFormSuccess('');
		//Before submit validate form
		if (validateForm()) {
			//submitForm({ email: form.email, password: form.password })
			setFormSuccess('Subscribed successfully.');
			setForm({ email: '', email_error: '' })
		}
	}
	return (
	<>
		<Header/>
			<main>
      <section className="banner_section">
        <div className="container">
          <div className="row v-align">
            <div className="col-md-7 col-sm-12 col_left_banner" data-aos="zoom-in" data-aos-duration="1000">
              <h1 className="banner_heading"> Build Your Own <span className="color_text">Payments</span>,
                Maximize Your <span className="color_text">Revenue.</span></h1>
                <p className="para_2 pt-3">Customize your payment and card solutions easily to fit your needs with
                  accuracy and smart thinking.</p>
                  <div className="second_btn pt-4">
                    <Link className="btn btn_new" to="/contact-us">Get Started Now <img src="images/arrow-right.svg" alt="" /></Link>
                  </div>
            </div>
            <div className="col-md-5 col-sm-12 col_right_banner" data-aos="zoom-in" data-aos-duration="1000">
              <div className="banner_img_area">
                <video className="video" autoPlay={true} muted={true} src="images/banner_new.mp4" data-object-fit="cover"></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutus_section" id="about_section">
        <div className="container position_relative">
          <div className="row v-align">
            <div className="col-lg-6 col-sm-12 text-center" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="600">
              <img className="animation_img" src="images/gif4.gif" alt="" />
            </div>
            <div className="col-lg-6 col-sm-12" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="200">
              <div className="about_us_left px-5">
                <div className="about_top aos-item">
                  <h4 className="about_top_heading">Introduce</h4>
                </div>
                <p className="para_1 pt-4">Founded in 1999 by industry experts, Credics specializes in bespoke billing software tailored for diverse business sectors globally. Our solutions are uniquely designed to adapt to various business needs, ensuring flexibility and scalability. At Credics, we integrate modular components to craft custom software solutions that not only meet specific requirements but also evolve with your business. Over two decades of experience has equipped us with the knowledge and adaptability to support your business's growth and diversification. With Credics, you gain a partner committed to enhancing your capabilities and services, ready to navigate the ever-changing landscape of multiple industries.</p>
                <ul className="pt-3">
                  <li className="listing_about">
                    <img src="images/logo_ic.svg" alt="" />
                    Rule-Based Software Engine
                  </li>
                  <li className="listing_about">
                    <img src="images/logo_ic.svg" alt="" />
                    Vast experience with processing companies worldwide
                  </li>
                  <li className="listing_about">
                    <img src="images/logo_ic.svg" alt="" />
                    Provide tailor-made solution for each requirements needs
                  </li>
                  <li className="listing_about">
                    <img src="images/logo_ic.svg" alt="" />
                    Short time to market of new financial products
                  </li>
                  <li className="listing_about">
                    <img src="images/logo_ic.svg" alt="" />
                    Covers End-2-End solution for payment cards, Integrated payment HUB
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="our_product_section" id="our_product">
        <div className="container position_relative">
          <div className="row justify-content-center text-center">
            <div className="about_top" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="600">
              <h4 className="about_top_heading">Our Products</h4>
            </div>
            <h2 className="heading_two py-4" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="200"> Perfect Solutions for
              <span className="color_text"> Customizing</span> Your Payment and Cards</h2>
          </div>
          <div className="product_tabbox" data-aos="fade-down" data-aos-duration="2000">
            <ul className="nav nav-tabs product_tablisting" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#product1"><img className="tab_arrowlink" src="images/tab_arrow.svg" alt="" />Payment Card Management</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#product2"><img className="tab_arrowlink" src="images/tab_arrow.svg" alt="" />Merchant Management & Transaction Processing</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#product3"><img className="tab_arrowlink" src="images/tab_arrow.svg" alt="" />Loyalty & Rewards</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#product4"><img className="tab_arrowlink" src="images/tab_arrow.svg" alt="" />Payment Hub</a>
              </li>
            </ul>
            <div className="tab-content product_tabcontent" data-aos="fade-down" data-aos-duration="1000">
              <div id="product1" className="container tab-pane active">
                <div className="producttab_content">
                <div className="product-tab-img">
                  <img className="tab_image" src="images/layer_svg.svg" alt="" />
                </div> 
                  <div className="product_content_inner">
                    <ul className="product_listing">
                      <li className="prodcut_list_item style_2">
                        <div className="product_icon">
                          <img src="images/logo_ic.svg" alt="" />
                        </div>
                        <p className="para_1">Accommodate any business model, including credit, debit, prepaid, private-label, loans, and loyalty programs.</p>
                      </li>
                      <li className="prodcut_list_item style_2">
                        <div className="product_icon">
                          <img src="images/logo_ic.svg" alt="" />
                        </div>
                        <p className="para_1">Easily tailor marketing efforts based on demographics, affinities, activity levels, holidays, etc.</p>
                      </li>
                      <li className="prodcut_list_item style_2">
                        <div className="product_icon">
                          <img src="images/logo_ic.svg" alt="" />
                        </div>
                        <p className="para_1">Merge data on multiple cards and transaction types through transactor-based tracking.</p>
                      </li>
                      <li className="prodcut_list_item style_2">
                        <div className="product_icon">
                          <img src="images/logo_ic.svg" alt="" />
                        </div>
                        <p className="para_1">Ensure swift data migration, integration, and enrichment for seamless operations and efficient access to user data.</p>
                      </li>
                    </ul>
                    
                  </div>
                </div>
              </div>
              <div id="product2" className="container tab-pane fade">
                <div className="producttab_content">
                  <div className="product-tab-img">
                    <img className="tab_image" src="images/layer_svg.svg" alt="" />
                  </div> 
                    <div className="product_content_inner">
                      <ul className="product_listing">
                       <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Integrated Transaction Processing:</h3>
                        <li className="prodcut_list_item style_2"> 
                          
                          <p className="para_1">Seamlessly execute financial transactions with our integrated platform, efficiently managing billing
                            processes.</p>
                        </li>
                      </ul>
                       <ul className="product_listing">
                        <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Multi-Currency and Payment Flexibility:</h3>
                        <li className="prodcut_list_item style_2">
                          
                          <p className="para_1">Support multi-currency processing, converting each transaction during OTB calculation. Additionally, easily handle issuing and acquiring from major payment systems like MasterCard, Visa, AMEX, and Discover.</p>
                        </li>
                        </ul>
                        <ul className="product_listing">
                          <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Comprehensive Clearing and Settlement:</h3>
                        <li className="prodcut_list_item style_2">
                          <p className="para_1">Experience end-to-end support for clearing, settlement, and reconciliation processes. Our system
                            offers built-in statistics, query, and monitoring controls, ensuring a consistently balanced system
                            irrespective of traffic demands.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
              </div>
              <div id="product3" className="container tab-pane fade">
                <div className="producttab_content">
                  <div className="product-tab-img">
                    <img className="tab_image" src="images/layer_svg.svg" alt="" />
                  </div> 
                  <div className="product_content_inner">
                    <ul className="product_listing">
                      <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Flexible Rewards:</h3>
                      <li className="prodcut_list_item style_2"> 
                        <p className="para_1">Seamlessly accumulate and redeem rewards through marketplaces, Buy Now Pay Later, and cashback programs.</p>
                      </li>
                    </ul>
                     <ul className="product_listing">
                      <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Multi-Program Management:</h3> 
                      <li className="prodcut_list_item style_2">
                        <p className="para_1">Manage multiple loyalty programs per account, offering diverse engagement options for customers.</p>
                      </li>
                      </ul>
                      <ul className="product_listing">
                        <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Instant POS Incentives:</h3> 
                      <li className="prodcut_list_item style_2">
                        <p className="para_1">Provide real-time point-of-sale (POS) loyalty incentives for an enhanced customer experience and immediate participation.</p>
                      </li>
                    </ul>
                    <ul className="product_listing">
                      <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Personalized Discounts:</h3> 
                    <li className="prodcut_list_item style_2">
                      <p className="para_1">Enable tailored discounts and rebates with customer-specific terms,
                        ensuring an attractive offering for loyalty program participants.</p>
                    </li>
                  </ul>
                    
                  </div>
                  </div>
              </div>
              <div id="product4" className="container tab-pane fade">
                <div className="producttab_content">
                  <div className="product-tab-img">
                    <img className="tab_image" src="images/layer_svg.svg" alt="" />
                  </div> 
                  <div className="product_content_inner">
                    <ul className="product_listing">
                      <h3 className="product_inner_title"> <img src="images/logo_ic.svg" alt="" />Versatile Marketplace Platform:</h3>
                      <li className="prodcut_list_item style_2"> 
                        <p className="para_1">Facilitate Any2Any transactions, including miles, loans, and more on a dynamic marketplace platform.</p>
                      </li>
                    </ul>
                     <ul className="product_listing">
                        <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" />Comprehensive Digital Wallet:</h3> 
                      
                      <li className="prodcut_list_item style_2"> 
                        <p className="para_1">Access all relevant digital payment methods, including direct
                          payments from bank accounts, through a secure and user-friendly digital wallet.</p>
                      </li>
                      </ul>
                      <ul className="product_listing">
                        <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" /> Advanced Payment Management:</h3> 
                      <li className="prodcut_list_item style_2">
                        <p className="para_1">Utilize an advanced payment manager for account aggregation, installment payments, and combined transactions, streamlining financial operations.</p>
                      </li>
                    </ul>
                    <ul className="product_listing">
                      <h3 className="product_inner_title"><img src="images/logo_ic.svg" alt="" /> Integrated Loyalty Programs & Rewards:</h3> 
                    <li className="prodcut_list_item style_2">
                      <p className="para_1">Seamlessly integrate loyalty programs and rewards, enhancing customer engagement and creating personalized payment structures.</p>
                    </li>
                  </ul>
                    
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="business_type_section" id="business_section">
        <img className="business_lt_img" src="images/bg_business_lt.svg" alt="" />
        <img className="business_rt_img" src="images/bg_business_rt.svg" alt="" />
        <div className="container position_relative">
          <div className="row pb-2" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300">
            <div className="about_top mx-2">
              <h4 className="about_top_heading">Business Types</h4>
            </div>
          </div>
          <div className="row row_gap pt-5" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="600">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="business_type_box">
                <img className="business_type_image" src="images/financial-Institutions.jpg" alt="" />
                <h3 className="business_title">Financial Institutions & Credit Unions</h3>
                <p className="para_1">Elevate customer acquisition and revenue streams with our advanced card issuance platform designed for banks and credit unions. Tailored for banks and credit unions, with a focus on optimizing the elements of any type of payment cards.</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="business_type_box">
                <img className="business_type_image" src="images/FinTech-innovation-lab.jpg" alt="" />
                <h3 className="business_title">FinTech Innovation Lab</h3>
                <p className="para_1">Explore a world of possibilities with our Fintech Lab Services, Tailored for both small startups and large enterprises. Transform your dreams and ideas into reality with our team of experts, backed by a wealth of proven experience.</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="business_type_box">
                <img className="business_type_image" src="images/Petroleum-Companies.jpg" alt="" />
                <h3 className="business_title">Petroleum Companies</h3>
                <p className="para_1">Credics provides a full suite of services for fuel card issuers and energy companies. Our offerings include EMV chip 'n pin card issuance, acquirer switching, online authorizations, transaction processing, clearing and settlement reconciliation, and
                  account management.</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="business_type_box">
                <img className="business_type_image" src="images/Online-Payment.jpg" alt="" />
                <h3 className="business_title">Retail & Online Payment</h3>
                <p className="para_1">Boost your sales with diverse payment options! Don't miss out on sales opportunities, start integrate popular online payment methods. Customize your checkout page with your preferred payment options.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tabbox_section">
        <div className="container">
          <ul className="nav nav-tabs tabbox_listing" role="tablist" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300">
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab" href="#establishment">
              <img className="tab_icon_tabbox" src="images/company-1.svg" alt="" />
              Establishment
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                <img className="tab_icon_tabbox" src="images/boss.png" alt="" />
                Integration of the system in major industry leaders.
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#menu2">
                <img className="tab_icon_tabbox" src="images/goal-1.svg" alt="" />
                Establishment of Credics USA
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#menu3">
                <img className="tab_icon_tabbox" src="images/problem-solving-1.svg" alt="" />
                Kickstarting an AI-powered solution..
              </a>
            </li>
          </ul>
          
          <div className="tab-content" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="600">
            <div id="establishment" className="container tab-pane active">
              <div className="tab-content-image-area">
                <img className="tab_image" src="images/Establishment1.jpg" alt="" />
              </div>
            </div>
            <div id="menu1" className="container tab-pane fade">
              <div className="tab-content-image-area">
                <img className="tab_image" src="images/tab1_img.jpg" alt="" />
              </div>
            </div>
            <div id="menu2" className="container tab-pane fade">
              <div className="tab-content-image-area">
                <img className="tab_image" src="images/american-flag.jpg" alt="" />
              </div>
            </div>
            <div id="menu3" className="container tab-pane fade">
              <div className="tab-content-image-area">
                <img className="tab_image" src="images/ai-image.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials_section">
        <div className="container">
          <div className="row v-align">
            <div className="testimonials_image col-lg-6 text-center" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500">
              <img src="images/Testimonials-img.png" alt="" />
            </div>
            <div className="col-lg-6" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="800">
              <div className="about_col ps-5 pe-4">
                <h2 className="heading_two py-3">
                  <span className="color_text">Client</span> Experiences That Matter.
                </h2>
                <div id="demo" className="carousel slide testimonials_area" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <p className="para_3 pt-5 pb-4">"In case of problems on authorization system I usually get immediate attention
                        (the time of a phone call), and the problem solved in less than ten minutes…”</p>
                      <div className="name_area">
                        <h4 className="name_title">Pietro Salinari,</h4>
                        <p className="para_4">CTO</p>
                        <p className="para_5">Unipol Banca – Italy</p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <p className="para_3 pt-5 pb-4">"They are reliable and always deliver on time what they promise. I also think they have a deep knowledge in credit cards industry…”</p>
                      <div className="name_area">
                        <h4 className="name_title">Marco Bombarda,</h4>
                        <p className="para_4">IT Director</p>
                        <p className="para_5">First Data Int. Europe</p>
                      </div>
                    </div>
                  </div>

                  <div className="carousel_slide_btn">
                    <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                      <img src="images/carousal_arrow_lt.svg" alt="" />
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                      <img src="images/carousal_arrow_rt.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className="row pt-5" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="800">
            <ul className="brands_logo_bottom d-flex align-items-center justify-content-between pt-5">
              <li><img src="images/first-data-logo.svg" alt="" /> </li>
              <li><img src="images/unicard-logo.svg" alt="" /> </li>
              <li><img src="images/veracity-logo.svg" alt="" /> </li>
              <li><img src="images/la-poste-logo.svg" alt="" /> </li>
              <li><img src="images/postilion-logo.svg" alt="" /> </li>
              <li><img src="images/bp-logo.svg" alt="" /> </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="newsletter_section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-5" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500">
              <h4 className="newsletter_heading">Ready to get started?</h4>
              <p className="para_2 style_2 pt-3">Explore Credics, or create an account instantly and start customize your payment and card solutions. You can also contact us for more information.</p>
              <div className="second_btn pt-4">
                <a className="btn btn_new" href="https://obsidiantechno.com/credics/contact-us.html">Get Started Now <img src="images/arrow-right.svg" alt="" /></a>
              </div> 
            </div>
            <div className="col-md-6 newsletter_padding" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500">
              <h4 className="newsletter_heading">Subscibe to our newsletter</h4>
              <form className="newsletter_form pt-3">
                <input className="newsletter_input" type="email" name="email" id="email" value={form.email} onChange={valueChanged} placeholder="Your Email Address" />
				<span className="small text-danger">{form.email_error}</span>
				<span className="small text-success">{formsuccess}</span>
                <div className="pt-4">
                  <a className="btn btn_new" onClick={handleSubmit}>SUBSCRIBE NOW</a>
                </div>
              </form> 
            </div>
          </div>
        </div>
      </section>
    </main>

		<Footer />
	</>
	)
}

export default Home
