import React from 'react'
import Header from './Common/Header';
import Footer from './Common/Footer';

const CreditUnion = () => {
	
	
	return (
	<>
		<Header />
			<main>
      <section className="inner_page_banner">
        <img className="banner_innerpage" src="images/tab1_img.png" alt="" />
        <div className="container position_relative">
            <div className="innerpage_banner text-center" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="600">
                <h1 className="banner_heading text-white">Credit <span className="color_text">Unions</span></h1>
            </div>
        </div>
      </section>

      <section className="credit_section">
        <div className="container position_relative">
          <div className="row v-align pb-5">
            <div className="col-md-6 text-center" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="600">
              <img className="credit_union_image" src="images/credit_union_img.png" alt="" />
            </div>
            <div className="col-md-6" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="600">
              <div className="about_us_left pe-5">
                <div className="about_top">
                  <h4 className="about_top_heading">Credit Unions</h4>
                </div>
                <p className="para_1 pt-4">In the dynamic world of credit unions, fintech needs are increasingly complex, calling for innovative, secure, and adaptable solutions. With over two decades of experience, Credics Technologies expertly addresses these needs by offering a comprehensive suite of products including Payment Cards, Digital Wallets, Integrated Payment Solutions, Risk Management and Fraud Detection, and Loyalty Programs. Our commitment goes beyond mere provision; we specialize in customizing these platforms to meet the unique requirements of each credit union, ensuring a perfect fit for your specific needs.</p>
                <div className="second_btn pt-4">
                  <a className="btn btn_new" href="#">Let's talk <img src="images/arrow-right.svg" alt="" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="text-center"  data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300"> 
              <h2 className="heading_two">Our <span className="color_text">Solutions</span></h2>
            </div>
            <div className="our_solution_point"  data-aos="fade-down" data-aos-duration="1000" data-aos-delay="800">
              <div className="our_solution_items">
                <div className="our_solution_box">
                  <img className="our_solution_image" src="images/credit-card1.svg" alt="" />
                  <h3 className="business_title solution_title">Payment card</h3>
                </div>
              </div>
              <div className="our_solution_items">
                <div className="our_solution_box">
                  <img className="our_solution_image" src="images/digital-wallet1.svg" alt="" />
                  <h3 className="business_title solution_title">Digital Wallet</h3>
                </div>
              </div>
              <div className="our_solution_items">
                <div className="our_solution_box">
                  <img className="our_solution_image" src="images/cash-flow1.svg" alt="" />
                  <h3 className="business_title solution_title">Intergarte payment sulotions</h3>
                </div>
              </div>
              <div className="our_solution_items">
                <div className="our_solution_box">
                  <img className="our_solution_image" src="images/operational-risk1.svg" alt="" />
                  <h3 className="business_title solution_title">Risk management, Fraud detection</h3>
                </div>
              </div>
              <div className="our_solution_items">
                <div className="our_solution_box">
                  <img className="our_solution_image" src="images/loyal-customer1.svg" alt="" />
                  <h3 className="business_title solution_title">Loyalty programs</h3>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </main>
		<Footer />
	</>
	)
}

export default CreditUnion
