import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Header = (props) => {
	
	return (
		<header>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/"><img src="images/logo-dark.svg" alt="" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img src="images/nav_toggle_ic.svg" alt="" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar_div">
              <ul className="navbar-nav gap-4 me-auto mb-2 mb-lg-0">
                <div className="inner_header_mobile">
                  <img src="images/logo_ic.svg" alt="" />
                  <button className="navbar-toggler close_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <img src="images/close_ic.svg" alt="" />
                  </button>
                </div>
                <li className="nav-item dropdown">
                  <HashLink className="nav-link text-dark dropdown-toggle" to='/#our_product' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                    Products
                    <img className="arrow_down_img" src="images/arrow-down.svg" alt="" />
                    <img className="arrow_up_img" src="images/arrow_up.svg" alt="" />
                  </HashLink>
                  <ul className="dropdown-menu">
                    <div className="inner_header_mobile">
                      <img src="images/logo_ic.svg" alt="" />
                      <HashLink className="nav-link text-dark dropdown-toggle" to='/#our_product' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        Products               
                        <img style={{width: '20px'}} src="images/close_ic.svg" alt="" />
                      </HashLink>
                    </div>
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#our_product' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/payment-method-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Payment Card Management</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
      
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#our_product' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/transaction-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Merchant Management & Transaction processing</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
      
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#our_product' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/rewards-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Loyalty & Rewards</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
      
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#our_product' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/card-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Payment Hub</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
                  </ul>
                </li>
  
                <li className="nav-item dropdown">
                  <Link className="nav-link text-dark" to="/credit-union">Credit Union </Link>
                </li>
  
                <li className="nav-item dropdown">
                  <HashLink className="nav-link text-dark dropdown-toggle" to='/#business_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                    Business types
                    <img className="arrow_down_img" src="images/arrow-down.svg" alt="" />
                    <img className="arrow_up_img" src="images/arrow_up.svg" alt="" />
                  </HashLink>
                  <ul className="dropdown-menu">
                    <div className="inner_header_mobile">
                      <img src="images/logo_ic.svg" alt="" />
                      <HashLink className="nav-link text-dark dropdown-toggle" to='/#business_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        Business Types               
                        <img style={{width: '20px'}} src="images/close_ic.svg" alt="" />
                      </HashLink>
                    </div>
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#business_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/financial-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Financial Institutions & Credit Unions</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
      
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#business_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/retail-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Retail</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
      
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#business_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/technology-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>FinTech Innovation Lab</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
      
                    <li className="dropdown-items">
                      <HashLink className="dropdown-menu-items" to='/#business_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/petroleum-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Petroleum Companies</h4>
                            </div>
                          </div>
                        </div>
                      </HashLink>
                    </li>
                  </ul>
                </li>
  
                <li className="nav-item dropdown">
                  <HashLink className="nav-link text-dark dropdown-toggle" to='/#about_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                    About
                    <img className="arrow_down_img" src="images/arrow-down.svg" alt="" />
                    <img className="arrow_up_img" src="images/arrow_up.svg" alt="" />
                  </HashLink>
                  <ul className="dropdown-menu">
                    <div className="inner_header_mobile">
                      <img src="images/logo_ic.svg" alt="" />
                      <HashLink className="nav-link text-dark dropdown-toggle" to='/#about_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                        About               
                        <img style={{width: '20px'}} src="images/close_ic.svg" alt="" />
                      </HashLink>
                    </div>
                    <li className="dropdown-items">
					   <HashLink className="dropdown-menu-items" to='/#about_section' scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} >
						<img src="images/call-icon.svg" alt="" />
                        <div className="dropdown-items-inner">
                          <div className="dropdown-box">
                            <img src="images/payment-method-icon.svg" alt="" />
                            <div className="dropdown-content">
                              <h4>Company</h4>
                            </div>
                          </div>
						</div>
                      </HashLink>
                    </li>
                  </ul>
                </li>
                
              </ul>
              <div className="button-header ul_btn">
                <Link className="btn header_btn" to="/contact-us"><img src="images/call-icon.svg" alt="" />CONTACT US</Link>
              </div>
            </div>
            <div className="d-flex display_none">
              <div className="button-header">
               <Link className="btn header_btn" to="/contact-us"><img src="images/call-icon.svg" alt="" />CONTACT US</Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>

	)
}

export default Header
